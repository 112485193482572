import { API_URL } from 'lib/constants/envConstants';
import axios from 'axios';

type ContactUsDetails = {
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  message: string;
};

export type NoOpApplicationInfo = {
  acres: Number;
  email: string;
  polygon: string;
  screenshot: string;
};

export type AuthenticationRequest = {
  email: string;
  password: string;
};

export type PolygonCoordinate = {
  x: number;
  y: number;
};

export type Application = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  postcode: string;
  screenshot: string;
  opportunity: string;
  polygon: PolygonCoordinate[];
  priceEstimate: number;
  acres: number;
};

export type ApplicationSummary = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  postcode: string;
  opportunity: string;
};

interface LandFeature {
  name: string;
  type: string;
  polygon: MultiPolygon;
}

type Position = [number, number];

type LinearRing = Position[];

type Polygon = LinearRing[];

type MultiPolygon = {
  type: 'MultiPolygon';
  coordinates: Polygon[];
};

type Scores = {
  difficulty: number;
  eco_friendly: number;
  land_retention: number;
  planning_constraints: number;
  timescale: number;
  value: number;
};

type Step = {
  id: number;
  step: string;
  instructions: string;
};

type AvailableOpportunity = {
  opportunity_name: string;
  price: number;
  postcode: string;
  diy: boolean;
  diy_provider_url: string;
  diy_provider_image: string;
  scores: Scores;
  steps: Step[];
};

export type AvailableOpportunities = {
  acreage: number;
  agr_grade: number;
  opportunities: AvailableOpportunity[];
};

export interface LandQueryResult {
  land_features: LandFeature[];
  available_opportunities: AvailableOpportunities;
}

function authHeaders() {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.accessToken}`
  };
}

export async function contactUs(params: ContactUsDetails) {
  return await axios.post(
    API_URL + '/contact-us',
    {
      firstName: params.firstName,
      lastName: params.lastName,
      email: params.email,
      telephone: params.telephone,
      message: params.message
    },
    { headers: authHeaders() }
  );
}

export async function noOpportunity(params: NoOpApplicationInfo) {
  return await axios.post(
    API_URL + '/no-opportunity',
    {
      acres: params.acres,
      polygon: params.polygon,
      email: params.email,
      screenshot: params.screenshot
    },
    { headers: authHeaders() }
  );
}

export async function authenticate(params: AuthenticationRequest) {
  return await axios.post(
    API_URL + '/authenticate',
    {
      email: params.email,
      password: params.password
    },
    { headers: authHeaders() }
  );
}

export async function getApplications() {
  return await axios.get(API_URL + '/applications', { headers: authHeaders() });
}

export async function getApplication(applicationId: string) {
  return await axios.get(API_URL + `/applications/${applicationId}`, {
    headers: authHeaders()
  });
}

export async function getLandQuery(
  polygon: PolygonCoordinate[],
  acreage: number
): Promise<LandQueryResult> {
  return (
    await axios.post<LandQueryResult>(
      API_URL + `/land-query`,
      {
        polygon,
        acreage
      },
      {
        headers: authHeaders()
      }
    )
  ).data;
}

import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CommonProps } from 'Router';
import { getLandQuery, LandQueryResult } from 'lib/apis';

import Opportunities from './Opportunities';

import './index.scss';

const OpportunitiesPage = ({ setLoadingCallback }: CommonProps) => {
  const navigate = useNavigate();
  const [data, setData] = useState<LandQueryResult | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const polygon = useMemo(() => {
    const strObj: { [key: string]: string } | null = JSON.parse(
      localStorage.getItem('selectedArea') || '{}'
    );

    const res = [];

    if (strObj) {
      for (let i = 0; i < Object.keys(strObj).length / 2; i++) {
        res.push({
          x: Number(strObj[`x${i}`]),
          y: Number(strObj[`y${i}`])
        });
      }
    }

    return res;
  }, []);

  useEffect(() => {
    setLoading(true);
    const acreage = Number(localStorage.getItem('acres') || 0);
    getLandQuery(polygon, acreage).then(x => {
      setData(x);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setLoadingCallback({
      open: loading,
      title: 'Please wait as we make your land work for you.',
      description:
        'Your privacy is important to us. Your land will only be shared with project partners once applied.'
    });
  }, [loading]);

  useEffect(() => {
    const selectedArea = localStorage.getItem('selectedArea');

    if (!selectedArea) {
      navigate('/');
    }
  }, []);

  return <Opportunities data={data} />;
};

export default OpportunitiesPage;

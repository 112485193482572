import { FC, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { home } from 'lib/constants/routes';
import { useQuery } from '@apollo/client';
import fallbackImg from 'assets/imgs/fallback.png';
import {
  OPPORTUNITIES_CONTENT,
  PAYMENT_TYPE_DESCRIPTION
} from 'api/strapi/opportunities';
import { AvailableOpportunities, LandQueryResult } from 'lib/apis';

import DiyListItem from './DiyListItem';
import ListItem from './ListItem';
import ListItemSkeleton from './ListItemSkeleton';
import './opportunities.scss';
import EmptyOpportunity from './EmptyOpportunity';
import NotHappyOptions from './NotHappyOptions';
import ApplyToAll from './ApplyToAll';

export type Metrics = {
  Timescale: number;
  Difficulty: number;
  Value: number;
  'Eco Planning': number;
  'Land Retention': number;
};

interface Step {
  id: number;
  step: string;
  instructions: string;
}

interface Opps {
  src: string;
  price: number;
  title: string;
  metrics: Metrics;
  info: string;
  postcode: string;
  payment_type: string;
  diy: boolean;
  steps: Step[];
  diy_provider_url: string;
  diy_provider_image: string;
}

const getOppsList = (
  opps?: AvailableOpportunities,
  content?: any[]
): Opps[] | undefined => {
  if (opps && content) {
    return opps?.opportunities.map(elem => {
      return {
        src:
          content?.find(
            (o: any) => o.opportunity_name === elem.opportunity_name
          )?.image?.url || fallbackImg,
        price:
          elem.price === -1 || Number(localStorage.getItem('acres')) > 500
            ? 'Negotiable'
            : elem.price,
        postcode: elem.postcode,
        title: elem.opportunity_name,
        diy: elem.diy,
        diy_provider_url: elem.diy_provider_url,
        diy_provider_image: elem.diy_provider_image,
        payment_type:
          content?.find(
            (o: any) => o.opportunity_name === elem.opportunity_name
          )?.payment_type || '',
        metrics: {
          Timescale: elem.scores.timescale,
          Difficulty: elem.scores.difficulty,
          Value: elem.scores.value,
          'Eco Planning': elem.scores.eco_friendly,
          'Land Retention': elem.scores.land_retention
        },
        steps: elem.steps,
        info:
          content?.find(
            (o: any) => o.opportunity_name === elem.opportunity_name
          )?.opportunity_description || ''
      };
    }) as Opps[];
  }
};

interface Props {
  data?: LandQueryResult;
}

const Opportunities: FC<Props> = ({ data }) => {
  const [selectedValue] = useState<keyof Metrics | null>(null);

  const { data: content } = useQuery(OPPORTUNITIES_CONTENT, {
    context: {
      withoutToken: true
    }
  });

  const { data: paymentType } = useQuery(PAYMENT_TYPE_DESCRIPTION, {
    context: {
      withoutToken: true
    }
  });

  const oppsList = getOppsList(
    data?.available_opportunities,
    content?.opportunities
  );

  const list = useMemo(() => {
    if (selectedValue) {
      return oppsList?.sort(
        (a, b) => a.metrics[selectedValue] - b.metrics[selectedValue]
      );
    } else {
      return oppsList;
    }
  }, [selectedValue, data]);

  const allOpportunities = list;
  const diyOpportunities = allOpportunities?.filter(opp => opp.diy);
  const commercialOpportunities = allOpportunities?.filter(opp => !opp.diy);

  const renderCommercialOpps = () => {
    if (!allOpportunities) {
      return <ListItemSkeleton />;
    }

    return (
      <>
        <div className="opportunties-heading-bar">
          <h1>
            {commercialOpportunities?.length}{' '}
            {commercialOpportunities && commercialOpportunities.length != 1
              ? 'Opportunities'
              : 'Opportunity'}
          </h1>
          <div className="apply-to-all-container">
            {commercialOpportunities?.length &&
              commercialOpportunities.length > 1 && (
                <ApplyToAll
                  postcode={commercialOpportunities[0].postcode}
                  title={commercialOpportunities
                    .map(itm => itm.title)
                    .join(', ')}
                />
              )}
          </div>
        </div>
        {commercialOpportunities?.length === 0 && <EmptyOpportunity />}
        {commercialOpportunities?.map(item => (
          <ListItem
            {...item}
            key={item.title}
            payment_type={item.payment_type}
            payment_info={paymentType?.payment_type_descriptions?.[0]}
            scores={content?.scores?.[0]}
          />
        ))}
      </>
    );
  };

  const renderAlternativeOpps = () => {
    if (diyOpportunities?.length == 0) {
      return <></>;
    }

    return (
      <>
        <h1>Inspiration</h1>
        {diyOpportunities?.map(item => (
          <DiyListItem
            {...item}
            key={item.title}
            payment_type={item.payment_type}
            steps={item.steps}
            diy_provider_url={item.diy_provider_url}
            diy_provider_image={item.diy_provider_image}
            payment_info={paymentType?.payment_type_descriptions?.[0]}
            scores={content?.scores?.[0]}
          />
        ))}
      </>
    );
  };

  return (
    <div className="opportunities-outer-container">
      <div className="header"></div>
      <main className="main-content">
        <div className="opportunities-top-bar">
          <div className="back-link-container">
            <Link className="back-link" to={home}>
              &lt; Back to Search
            </Link>
          </div>
          <div className="plot-pip-container">
            <div className="plot-pip">
              <div>Your Plot</div>
              <div>{localStorage.getItem('acres')} Acres</div>
            </div>
          </div>
        </div>
        {renderCommercialOpps()}
        {diyOpportunities && renderAlternativeOpps()}
      </main>
      <NotHappyOptions />
    </div>
  );
};

export default Opportunities;
